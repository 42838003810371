export const PRIV_MODELS = 'MODELS';
export const PRIV_SECURITIES = 'SECURITIES';
export const PRIV_SECURITYSETS = 'SECURITYSETS';
export const PRIV_TEAMS = 'TEAMS';
export const PRIV_USERS = 'USERS';
export const PRIV_ROLES = 'ROLES';
export const PRIV_CUSTODIANS = 'CUSTODIANS';
export const PRIV_PORTFOLIOS = 'PORTFOLIOS';
export const PRIV_ACCOUNTS = 'ACCOUNTS';
export const PRIV_HOLDINGS = 'HOLDINGS';
export const PRIV_ESGTHEME = 'ESGTHEME';
export const PRIV_FIRMPREF = 'FIRMPREF';
export const PRIV_CUSTODIANPREF = 'CUSTODIANPREF';
export const PRIV_MODELPREF = 'MODELPREF';
export const PRIV_TEAMPREF = 'TEAMPREF';
export const PRIV_SECURITYPREF = 'SECURITYPREF';
export const PRIV_PORTFOLIOPREF = 'PORTFOLIOPREF';
export const PRIV_ACCOUNTPREF = 'ACCOUNTPREF';
export const PRIV_HOLDINGPREF = 'HOLDINGPREF';
export const PRIV_QUERIES = 'QUERIES';
export const PRIV_FULLIMPORT = 'FULLIMPORT';
export const PRIV_PARTIALIIMPORT = 'PARTIALIIMPORT';
export const PRIV_APPROVELEVEL1 = 'APPROVELEVEL1';
export const PRIV_APPROVELEVEL2 = 'APPROVELEVEL2';
export const PRIV_APPROVELEVEL3 = 'APPROVELEVEL3';
export const PRIV_APPROVEMODELCHG = 'APPROVEMODELCHG';
export const PRIV_APPROVEMODELASS = 'APPROVEMODELASSIGN';
export const PRIV_ALLOW_EDIT_ON_SECURITY_PRICE = 'EDITSECPRICE';
export const PRIV_ALLOW_EDIT_ON_SECURITY_TYPE = 'EDITSECTYPE';
export const PRIV_A = 'A';
export const PRIV_ORDEXEC = 'ORDEXEC';
export const PRIV_PROCESSALL = 'PROCESSALLORDERS';
export const PRIV_ORDEDIT = 'ORDEDIT';
export const PRIV_AUDIT = 'AUDIT';
export const PRIV_REPORTS = 'REPORTS';
export const PRIV_COMMUNITYMODEL = 'COMMUNITYMODEL';
export const PRIV_FIXEDINCOMEORDERS = 'FIXEDINCOMEORDERS';
export const PRIV_FIXEDINCOMESTATUS = 'FIXEDINCOMESTATUS';
export const PRIV_BASISCODEOVERRIDE = 'BASISCODEOVERRIDE';
export const PRIV_OVERRIDEDONOTLH = 'OVERRIDEDONOTTLH';
export const PRIV_ALLOWVSPINTAXHARVESTING = 'ALLOWVSPINTAXHARVESTING';
export const VIEWL = 'L';
export const VIEWV = 'V';
export const VIEWA = 'A';
export const VIEWE = 'E';
export const VIEWD = 'D';
export const PENDINGNEW = 'pending new';
export const NOTSENT = 'not sent';
export const SENT = 'new(sent)';
export const MINIMUM_NUMBER_OF_ACCOUNTS_TO_SPLIT_TRADE_AWAY_BLOCK = 4;
export const SPLIT_TRADE_AWAY_BLOCK_ERROR_MESSAGE = 'Trade Away Group must have at least 4 account orders in order to split the group.';
export const UNPROCESSED_TRADE_BLOCK_ERROR_MESSAGE = 'Following blocks can not be edited as they are already executed';
export const PENDINGCANCEL = 'pending cancel';
export const PENDINGREPLACE = 'pending replace';
export const PARTIALLYFILLED = 'partially filled';
export const LEVEL1APPROVED = 'Level 1 Approved';
export const LEVEL2APPROVED = 'Level 2 Approved';
export const APPROVED = 'Approved';
export const APPROVEDNONE = 'None';
export const SecurityStalePriceFilterId = 1;
export const PRIV_RESTRICTEDPLANS = 'RESTRICTEDPLANS';
export const RESTRICTEDPLAN_BuySecurity = 'Can buy';
export const RESTRICTEDPLAN_SellSecurity = 'Check Preferences';
export const PRIV_SLEEVES = 'SLEEVES';
export const PRIV_MACWEIGHTING = 'MACWEIGHTING';
export const PRIV_TACTICAL = 'TACTICALTOOL';
export const PRIV_OPTION_TRADING = 'OPTIONTRADING';
export const PRIV_COMMUNITYPORTAL = 'COMMUNITYPORTAL';
export const PRIV_QUEUE = 'QUEUE';
export const PRIV_ASTRO = 'ASTRO';
export const PRIV_ASTROOPTIMIZATION = 'ASTROOPTIMIZATION';
export const PRIV_ASTROADVANCEDSETTINGS = 'ASTROADVANCEDSETTINGS';
export const PRIV_ENABLETRADES = 'ENABLETRADES';
export const PRIV_PREVENTTRADEREJECTION = 'ALLOWORDERREJECTION';
export const PRIV_CUSTOMIMPORTS = 'CUSTOMIMPORTS';
export const PRIV_DELTAIMPORT = 'DELTAIMPORT';
export const PRIV_V2IMPORTENABLED = 'V2IMPORTENABLED';
export const PRIV_DASHBOARD_DEFAULTS = 'DASHBOARDDEFAULTS';
export const PRIV_PUBLIC_VIEWS = 'PUBLICVIEWS';
export const PRIV_NOTES = 'NOTES';

export const PRIV_REBALANCER = 'REBALANCER';
export const PRIV_RAISECASH = 'RAISECASH';
export const PRIV_SPENDCASH = 'SPENDCASH';
export const PRIV_CASHNEEDS = 'CASHNEEDS';
export const PRIV_TRADETOTARGET = 'TRADETOTARGET';
export const PRIV_GLOBALTRADES = 'GLOBALTRADES';
export const PRIV_QUICKTRADES = 'QUICKTRADES';
export const PRIV_TAXHARVESTING = 'TAXHARVESTING';
export const PRIV_LIQUIDATE = 'LIQUIDATE';
export const PRIV_TAGS = 'TAGS';
export const CUSTODIAL_CASH = 'CUSTODIAL_CASH';
export const CASH = 'Cash';
export const CASH_SYMBOL = '*Cash*';
export const SMA_SECURITY = 'SMA_SECURITY';
export const DOWNLOAD_FILE = 'Download File';

export const CustodailSymbols = ['CUSTODIAL_CASH'];   // OEMP-91 CR, if we got more symbols to have non editable custodial cash value, then we may add them to this array.

export const enum UserType {
  FirmAdmin = 1,
  TeamAdmin = 2,
  User = 3,
  APIOnly = 4,
  OrionAdmin = 5,
};

export const enum AllocationInstruction {
  Default = 1,
  Custom = 3
}

export const enum ApprovalLevels {
  Approved = 1,
  NotApproved = 2,
  PendingApproval = 3,
  Level1Approved = 4,
  Level2Approved = 5
};

/**
 * Orion Privileges which are referred from eclipse database
 */
export const enum Privileges {
  NONE = 0,
  TEAMS = 57,
  USERS,
  ROLES,
  MODELS,
  SECURITIES,
  PORTFOLIOS,
  ACCOUNTS,
  HOLDINGS,
  CUSTODIANS,
  FIRMPREF,
  CUSTODIANPREF,
  MODELPREF,
  TEAMPREF,
  SECURITYPREF,
  PORTFOLIOPREF,
  ACCOUNTPREF,
  HOLDINGPREF,
  QUERIES,
  FULLIMPORT,
  PARTIALIIMPORT,
  APPROVELEVEL1,
  APPROVELEVEL2,
  APPROVELEVEL3,
  APPROVEMODELCHG,
  APPROVEMODELASS,
  A,
  PRIV_ORDEXEC,
  PRIV_ORDEDIT,
  PRIV_AUDIT,
  REPORTS
};

/** Defines saved views viewtype enum */
export enum ViewTypeEnum {
  TeamListView = 1,
  PortfolioListView = 2,
  AccountListView = 3,
  HoldingListingView = 4,
  DashBoardQueryBuilderView = 5,
  ModelListView = 6,
  TradeOrderListView = 7,
  BlockListView = 8,
  DeletedTradesListView = 9,
  InstanceListView = 10,
  UnAllocatedBlockListView = 12,
  pendingTradesListView = 13,
  pendingBlocksListView = 14,
  ClosedTradeListView = 15,
  ClosedBlockListView = 16,
  TradeQueueListView = 37,
  AstroAlertsView = 39,
  SecuritySetListView = 40,
  TLHCreateTradesView = 41,
  TLHTradesFinalView = 42,
  MFActiveBatchesView = 43,
  MFActiveBatchedOrdersView = 44,
  MFClosedBatchesView = 45,
  SleeveStrategyView = 46,
  SleeveStrategyAggView = 47,
  OptionTradingOpenNewView = 48,
  OptionTradingCloseExistingView = 49,
  OptionTradingCoveredCallView = 50,
  OptionTradingProtectivePutView = 51,
  RestrictedPlansView = 52,
  AstroBatchesView = 53,
  AccountListDynamicView = 54,
  PortfolioSecurityPreference = 55,
  AccountSecurityPreference = 56,
  PortfolioTargetedSecurityPreference = 57,
  AccountTargetedSecurityPreference = 58,
  PortfolioHoldingSecurityPreference = 59,
  AccountHoldingSecurityPreference = 60,
  PortfolioESGRestrictedSecurityPreference = 61,
  SecurityPreferenceEquivalent = 62,
  SecuritySetDetailsView = 63,
  TradeOrderListDynamicView = 64,
  PortfolioListDynamicView = 65,
  UserListView = 66,
  RolesListView = 67,
  ModelToleranceCategory = 68,
  ModelToleranceClass = 69,
  ModelToleranceSubClass = 70,
  ModelToleranceSecuritySet = 71,
  ModelToleranceSecurity = 72,
  TradeDetailsHoldings = 73,
  TradeDetailsLots = 74,
  AccountDetails = 75,
  ModelToleranceSleeveStrategy = 76,
  ModelToleranceSleeve = 77,
  Layout = 78,
  Tactical = 79,
};

/** Defines the portfolio filters enum */
export const enum portfolioFiltersEnum {
  OutOfTolerance = 1,
  CashNeed = 2,
  SetForAutoRebalance = 3,
  Contributions = 4,
  Distributions = 5,
  NoModel = 6,
  DoNotTrade = 7,
  TaxLossHarvestOpportunity = 8,
  PendingTrades = 9,
  DataErrors = 10,
  WashSalesTrades = 11,
  OptionTradingOpportunities = 12,
  OptionsExpiringSoon = 13
};

/** Defines the account filters enum */
export const enum accountFiltersEnum {
  AccountsSetToSystematic = 1,
  AccountsWithMergeIn = 2,
  AccountsWithMergeOut = 3,
  NewAccounts = 4,
  AccountsWithNoPortfolios = 5,
  TODO = 6,
  SMA = 7,
  AccountsWithDataErrors = 8,
  AccountsWithPendingTrades = 9,
  accountWithTrackingErrorOutOfTolerance = 10,
  AstroAccounts = 11,
  OptionTradingOpportunities = 12,
  OptionsExpiringSoon = 13
};

/** Defines the enum for holding filters  */
export const enum holdingFiltersEnum {
  HoldingsNotInModel = 1,
  HoldingsExcludedFromTrading = 2
};

/** Defines the enumm for model status */
export const enum modelStatusEnum {
  APPROVED = 1,
  NOT_ACTIVE = 2,
  NOT_APPROVED = 3,
  DRAFT = 4
};

/** Defines the enumm for Tactical decimal Points */
export const enum tacticalDecimalEnum {
  amountDecimal = 6,
  quantityDecimal = 6,
  percentageDecimal = 6,
  quantityDecimalForDisplay = 4,
  amountDecimalForDisplay = 2,
  percentageDecimalForDisplay = 2,
  tacticalRoundingNumber = 7
};

export const enum tradeDecimalEnum {
  amountDecimal = 2,
  quantityDecimal = 3
};

export const NotificationSubType = {
  TradeInstanceCompleted: 'TradeInstanceCompleted',
  TradeInstanceError: 'TradeInstanceError',
  PendingOrders: 'PendingOrders',
  FixBlockOrder: 'FixBlockOrder',
  DataImportComplete: 'DataImportComplete',
  FullAnalysisComplete: 'FullAnalysisComplete',
  TradesNeedApproval: 'TradesNeedApproval',
  ModelAssignmentsNeedApproval: 'ModelAssignmentsNeedApproval',
  ModelNeedsApproval: 'ModelNeedsApproval',
  CommunityModelUpdated: 'CommunityModelUpdated',
  TLHBuyBack: 'TLHBUYBACK',
  ImportComplete: 'ImportComplete',
  ModelImportComplete: 'ModelImportComplete'
};

export const navigationLink = {
  Vieworders: 'View orders',
  Viewpendings: 'View pendings',
  Viewmodel: 'View models',
  Viewclosedorders: 'View closed orders',
  Errors: 'Errors',
  Viewoverviewdashboard: 'View overview dashboard',
  Viewportfolios: 'View portfolios',
  ViewImports: 'Success',
  ClickHere: 'Click here'
};

/** Defines the enum for account sleeve types */
export const sleeveTypeEnum = {
  None: 'None',
  Normal: 'Normal',
  Custodial: 'Custodial',
  Contribution: 'Contribution',
  Distribution: 'Distribution',
  Sleeve: 'Sleeve'
};

/** Defines notifiaction codes for import and analysis */
export const NotificationCode = {
  fullAnalysis: 'FULLANALYSIS',
  partialAnalysis: 'PARTIALANALYSIS',
  fullImport: 'DATAIMPORT',
  partialImport: 'PARTIALIMPORT'
};

/** Defines progress notificatrion statuses */
export const ProgressNotificationStatus = {
  COMPLETED: 'COMPLETED'
};

/** Defines security trade settings for preferences */
export const SecurityTradeSettings = {
  buy: 'Buy',
  sell: 'Sell'
};

export const decimalEnum = {
  decimalsForDollar: 4,
  decimalsForShares: 4,
  decimalsForPercent: 4
};

export const enum EmitNotificationType {
  NotificationWindow = 1,
  ProgressWindow = 2,
  MenuNotification = 3,
  TradeToolProgressNotification = 4,
  TradeProcessNotification = 5,
  TradeFileNotification = 6,
  MFBatchExecutionNotification = 7,
  ModelUpdateNotification = 8
};

export const DayNotationFilterType = {
  Today: 'Today',
  Yesterday: 'Yesterday',
  PreviousSevenDays: 'Previous 7 Days',
  Older: 'Older'
};

/** Trade tool types */
export const enum TradeToolType {
  SpendCash = 1,
  RaiseCash = 2,
  ProratedCash = 3,
  GlobalTrades = 4,
  CashNeed = 6,
  TradeToTarget = 7
};

export const enum BuySellPriority {
  CanBuy = 3,
  CanSell = 4
};

export const enum EquivalentType {
  Category = 1,
  Class = 2,
  SubClass = 3
};

export const enum QueryBuilderColumnsDataTypes {
  Int = 1,
  Varchar = 2,
  Date = 3,
  DateTime = 4,
  TinyInt = 5,
  Decimal = 6,
  LongText = 7,
  Bit = 8,
  BigInt = 9,
  Text = 10
};

export const enum AsideCashAmountType {
  Dollar = 1,
  Percentage = 2
};

export const enum AsideCashAccountType {
  Contribution = 1,
  Distribution = 2,
  ProRatedBasedOnContribution = 3,
  ProRatedBasedOnDistribution = 4
};

export const SpendRaiseCashRouteType: {} = {
  account: 'account',
  portfolio: 'portfolio'
};

export const BlendedFunds = {
  FirmDefault: 'Firm Default',
  EnableWarning: 'Warning: The security is set as a Target or Location Alternative or TLH Alternative in the below MAC enabled models. The security will be marked as a ‘Do Not BUY/SELL’ for all the portfolios assigned to the models. Press Ok to continue or Cancel to discard.',
  EnableError: 'Error: We can’t process this request as the security you are trying to make a 100% allocation, is currently a MAC enabled security on the below listed Models/Portfolios. Click “Convert” to have the system remove the security from the below listed models/portfolios. Click “Cancel” to cancel this request.'
};
export const MACValidationRulesMessages = {
  donotBuySellWarningMessage: 'The below MAC securities are defined as Target or Location Alternative or TLH Alternative in the selected models and are marked as ‘Do not BUY/SELL’ now. They can only be set as Equivalents. Please click ‘Edit’ to update the security sets”. Click ‘Continue’ to let them be marked as ‘Do not BUY/SELL’.',
  pureAssetClassWarningMessage: 'Due to node missing, the MAC securities will be treated as 100% pure asset class.  If the MAC security is not part of the model, Rebalancer will sell 100% of it as an unassigned security. Please add the missing nodes to the models if you do not want the securities to be treated as 100% pure asset class.',
  alternativePureAllocationMessage: 'The below MAC securities are added to the MAC models and are defined as Target or Location Alternative or TLH Alternative. In order for a security to follow the MAC rules, it must only be used as an equivalent. If you do not remove the securities from the security sets, the securities will be classified as 100% pure allocation in the model. Please click ‘Edit’ to update the security sets or click ‘Continue’ to let them be used as pure allocation.',
  rankValidationWarningMessage: 'The securities selected have overlap under same model nodes. Please assign unique ranks for the securities',
  portfolioWarningMessage: 'The portfolio has MAC weightings. Press ‘OK’ to continue.',
  modelNotMACModel: 'Model assigned to this portfolio is not MAC enabled',
  modelNotMACModelForAccount: 'Model assigned to this account is not MAC enabled',
  removingMacAtModelMessage: 'Disabling MAC will change the MAC status of assigned portfolios/accounts from "MODEL" to "NONE"',
  addingMACAtModelMessage: 'Enabling MAC will change the MAC status of assigned portfolios/accounts from "NONE" to "MODEL"'
};
export const MACWeightingStatus = {
  Yes: {value: 3, text: 'Yes'},
  No: {value: 1, text: 'No'},
  None: {value: 1, text: 'None'},
  Portfolio: {value: 2, text: 'Portfolio'},
  Model: {value: 3, text: 'Model'},
  Sleeve: {value: 4, text: 'Sleeve'}
};

export const MacEntityTypes = {
  Model: 'model',
  Portfolio: 'portfolio',
  Account: 'account',
  Sleeve: 'sleeve'
};

export const MACEditActions = {
  Add: 'Add',
  Update: 'Edit',
  Delete: 'Delete'
};

export const enum ActionType {
  BUY = 1,
  Sell = 2,
  Rebalance = 3,
  Buy_Rebalance = 4,
  Sell_Rebalance = 5,
  Liquidate = 6
};

export const TradeQueueStatus = {
  QUEUED: 1,
  PROCESSING: 2,
  COMPLETED: 3,
  DISABLED: 4,
  ERROR: 5
};

/** Defines the account filters enum */
export const enum AstroFilter {
  TrackingErrorOutOfTolerance = 1,
  RiskOutOfTolerance = 2,
  TaxLossHarvestingOpportunities = 3,
  CashOutOfTolerance = 4,
  NewTemplateAssignments = 5
};

/** Defines messages enum */
export const Messages = {
  dashbardFilterOverRiddenMessage: 'User has overridden the firm dashboard defaults',
  modelHaveDeletedSecuritiesMessage: 'Model contains a deleted security; cannot be assigned to any portfolio',
  reports: {
    noTradeMessage: 'Please select at least one trade.',
    noEnableTradeMessage: 'Please select at least one enable trade.',
    invalidTradeMessage: (invalidTradeCount: number, totalTradeCount: number) =>
      `${invalidTradeCount} out of ${totalTradeCount} of selected trades are outdated, So trade analysis report will not generated for these trades`
  },
  smaWarningMessage: 'Removing this sub-model will also remove the SMA weightings assigned to it in other models. Do you wish to continue?',
  liquidateSecuritiesWarningMessage: 'Equivalent holdings will be liquidated for this instance only. If you do not update your models ' +
    'these securities could be repurchased in future trading.',
  agGrid: {
    noRowToShow: 'No Rows to Show',
    cashSecurityMessage: (field: string) => `Can not set ${field} to a cash security.`
  },
  model: {
    invalidSubstitute: 'Please Enter a valid substitute'
  },
  tradeTools: {
    missingTrades: (type: string) => `Unable to fetch trades for some ${type}`,
    noTLHOpportunity: 'No security found with TLH opportunities.'
  },
  security: {
    TRADEABLE_CUSTODIAL_CASH: 'Tradable money market securities must be marked as custodial cash. Please set this security as custodial cash before continuing.',
    DISABLE_TRADABLE_MONEY_MARKET_SETTING: (field: string) => `This security is a tradable money market which requires it to be marked as ${field}.` +
      'To continue, please disable the tradable money market setting.'
  },
  disableMinMaxAmountFieldsWarning: 'Multiple accounts have been selected so only the minimum & maximum multiplier can be used. ' +
    'For each account this will multiply the amount being raised by the percentage entered, and set that as minimum and maximum set ' +
    'aside amounts. The minimum multiplier can be a value between 0-100%. The maximum multiplier can be a value of 100% or greater',
  percentBetweenZeroToHundred: (field: string) => `${field} should be between 0 to 100 percent.`,
  percentMoreThanHundred: (field: string) => `${field} should be more than or equal to 100 percent.`,
  selectValidRecords: 'Please select all valid records or validate the records.',
  balanceTradeError: (field: string) => `${field} security trades cannot be individually balanced and will not be included.`,
  averagePriceWarning: 'Average price is mandatory for manual orders.',
  invalidCustodianAlgoTags: 'Invalid Range/ Blank Algo Name for tags',
  overlappedCustodianAlgoTags: 'Overlapped range for tags',
  MODEL_ANALYZER: {
    EQUIVALENT_ALTERNATE_TOOL_TIP: (securityRelatedType: string) => `Account ${securityRelatedType} are not shown here, view additional details in tactical.`
  }
};

export const TaxHarvestType = {
  MANUAL: 1,
  AUTOMATED: 2
};

export const AnalyticsStatus = {
  Good: 0,
  NeedAnalytics: 1,
  Running: 2,
  Failed: 3
};

export const enum AnalyticsStatusValue {
  Good = 'No',
  NeedAnalytics = 'Yes',
  Running = 'In Progress',
  Failed = 'Failed'
};

export const OrderStatus = {
  NotSent: 1,
  Sent: 2,
  Partially_Filled: 3,
  Filled: 4,
  Done_For_Day: 5,
  Rejected: 7,
  Pending_Cancel: 8,
  Canceled: 9,
  Reviewed: 14
};

export const OrderType = {
  Market: 1,
  Limit: 2,
  Stop: 3,
  Stop_Limit: 4,
  ManualOrder: 6
};

export const OrderStatusName = {
  Filled: 'Filled',
  Rejected: 'Rejected',
  NotSent: 'Not Sent'
};

export const AllocationOption = {
  MarkComplete: 'markComplete'
};

export const buyPriority = {
  DO_NOT_BUY: 1,
  CAN_BUY: 4
};

export const sellPriority = {
  CAN_SELL:1,
  ALWAYS_SELL_ALL: 7
};

export const enum ModuleName {
  Account = 'Account',
  Portfolio = 'Portfolio',
  Trade = 'Trade'
};

export const SecurityTypeName = {
  Equity: 'Equity',
  MutualFund: 'Mutual Fund',
  Option: 'Option'
};

export const TradeFilterMethod = {
  SLEEVE_PORTFOLIO: 'sleevePortfolio',
  TRADE_GROUPS_FOR_ACCOUNT: 'tradeGroupsForAccount',
  TRADE_GROUPS_FOR_PORTFOLIO: 'tradeGroupsForPortfolio',
  ACCOUNT: 'account',
  PORTFOLIO: 'portfolio'
};

export const ViewName = {
  CREATE_TRADES_TAB: 'createTradesTab',
  SELECTION_TAB: 'SelectionTab',
  FINAL_TAB: 'finalTab'
};

export const TradeToolNameForTactical = {
  REBALANCE: 'rebalance',
  CASH_NEED: 'cashNeed',
  RAISE_CASH: 'raiseCash',
  SPEND_CASH: 'spendCash',
  TAX_HARVESTING: 'taxHarvesting',
  EDIT_SECURITY_SET: 'editSecuritySet',
  LIQUIDATE: 'liquidate'
};

export const TaxableType = {
  TAXABLE: 'TAXABLE',
  TAXEXMT: 'TAXEXMPT',
  TAXDEF: 'TAXDEF'
};

export const Tolerance = {
  OOT: 'OOT',
  Balanced: 'Balanced',
  InTolerance: 'In Tolerance'
};

export const enum AlertMessageType {
  Warning = 3,
  Error = 4
}

export const ESGThemeMessages = {
  cannotDeleteESGSecurity: 'Can not delete security assigned to an ESG theme .'
};

export const ModelLevels = {
  security: 'security'
};

export const UnassignedNode = 'Unassigned';
export const Excluded = 'Excluded';

export const REVERSE_SYNC_TYPE = {
  REBALANCE: 'Rebalance',
  ORDER_DELETE: 'Order Delete'
};

export const SECURITY_NAME = {
  CUSTODIAL_CASH: 'Custodial Cash',
  SMA: 'SMA_'
};

export const SECURITY_FIELD = {
  SYMBOL: 'symbol'
};

export const UPLOAD_RESULT = {
  INVALID_RECORD: 'INVALID',
  VALID_RECORD: 'VALID',
  VALID_RECORD_MESSAGE: 'All records are valid',
  INVALID_RECORD_MESSAGE: 'All records are invalid'
};

export const PREFERENCE_LEVEL = {
  FIRM: 'firm',
  CUSTODIAN: 'custodian',
  TEAM: 'team',
  MODEL: 'model',
  PORTFOLIO: 'portfolio',
  ACCOUNT: 'account',
  PORTFOLIOS: 'portfolios'
};

export const ACCOUNT_ASSIGN = {
  SUCCESS: 'Portfolio assigned to accounts successfully'
};

export const ANALYTICS_TRIGGER = {
  ACCOUNT_EDIT: 'account_edit',
  PREFERENCE_EDITS_ALL_OTHER_LEVELS: 'preference_edits_all_other_levels',
  PREFERENCE_EDITS_FIRM: 'preference_edits_firm'
};

export const PORTFOLIO_ACCOUNT_SECURITY_TYPE = {
  TARGET: 'Target',
  EQUIVALENT: 'Equivalent',
  NOT_TARGETED: 'Not Targeted'
};

export const PREFERENCE_NAME = {
  BASIS_CODE: 'BasisCode',
  USE_NEW_ASTRO_SERVICE: 'UseNewAstroService',
  ENABLE_REBALANCER_COMPARISON_TOOL: 'enableRebalancerComparisonTool',
  AUTO_ALLOCATE: 'autoAllocate'
};

export const enum IMPORT_EXPORT_MODULE_NAME {
  SECURITY_SET = 'Security Set',
  PORTFOLIO_EQUIVALENTS = 'Portfolio Equivalents',
  ACCOUNT_EQUIVALENTS = 'Account Equivalents'
};

export const enum RELATED_TYPE {
  PORTFOLIO = 16,
  ACCOUNT = 32
};

export const MIN_TRADE_AMOUNT_TYPE = {
  DOLLAR: '$',
  PERCENTAGE: '%'
};

export const enum ORDER_TYPE {
  MARKET = 'Market'
};

export const TERM = {
  SHORT_AND_LONG_TERM: 1,
  SHORT_TERM: 2,
  LONG_TERM: 3
};

export const GAIN_LOSS = {
  GAIN: 1,
  LOSS: 2
};

export const AMOUNT_TYPE = {
  ALL: 'all'
};

export const SECURITY_FILTER_TYPE = {
  ALL: 'all'
};

export const TLH_TAB = {
  FINAL_TAB: 'finalTab',
  SELECTION_TAB: 'SelectionTab',
  ACCOUNT_SELECTION_TAB: 'accountSelectionTab'
};

export const HTTP_RESPONSE_CODE = {
  SUCCESS: '200',
  BAD_REQUEST: '400'
};

export const RELATED_TYPE_ID = {
  FIRM: 1,
  CUSTODIAN: 2,
  TEAM: 4,
  MODEL: 8,
  SECURITYSET: 12,
  PORTFOLIO: 16,
  ACCOUNT: 32
};

export const FILE_TYPE = {
  CSV: 'csv',
  EXCEL: 'xlsx'
};

export const TRADE_EXECUTION_TYPE = {
  FIX_DIRECT: 1,
  Pending_Cancel: 8
};

export const SPLIT_IO_FLAG = {
  MOVE_TAX_TICKER_SWAP: 'move_tax_ticker_swap_s244716',
  SHOW_OPTIMIZATION_STACKED_BAR : 'AstroOptimization_ShowStackedBar_3188'
};

export const RELATED_TYPE_NAME = {
  1: 'Firm',
  2: 'Custodian',
  4: 'Team',
  8: 'Model',
  12: 'Security Set',
  16: 'Portfolio',
  32: 'Account'
};

export enum TRADE_ORDER_DECIMAL_PLACE {
  SHARE_PLACE = 6
}

export const enum YES_NO_ENUM {
  NO = 0,
  YES = 1
};

export const YES_NO_VALUE = {
  NO: 'No',
  YES: 'Yes'
};

export const DEFAULT_DECIMAL_PLACES = {
  ORDER_QUANTITY: 6,
  ORDER_AMOUNT: 6,
  REMAINING_AMOUNT: 3
};

export const NOTIFICATION_CATEGORY = {
  ANALYTICS_PORTFOLIO_STATUS: 'AnalyticsPortfolioStatus'
};

export const DEFAULT_TICKER_TAX_OPTION = {
  SELECT: 1,
  GAIN: 1,
  PERCENT: 2,
  ZERO: 0
};

export const GLOBAL_TRADE_TAB_VIEW = {
  CREATE_TRADE_TAB: 'createTradesTab',
  FINAL_TAB: 'finalTab',
  TAX_CONSIDERATION_TAB: 'taxConsiderationTab',
  DEFAULT_HEADER_NAME: '4 Final',
  HEADER_NAME_WITH_FLAG_OFF: '3 Final'
};

export const GLOBAL_TRADE_CONSTANTS = {
  VALIDATE_VALUE_CONSTANT: 2
};

export const TRADE_AND_HOLDINGS = {
  COLUMN_IDS: {
    BUY_AMOUNT: 'buyAmount',
    SELL_AMOUNT: 'sellAmount',
    TRADE_AMOUNT: 'tradeAmount'
  }
};

export const SMA_EDIT_POPUP = {
  ERROR_MESSAGES: {
    ZERO_PERCENT_ERROR: '0% weightings are not allowed.',
    EMPTY_PERCENT_ERROR: 'Enter a valid weightings percentage.',
    TOTAL_NOT_HUNDRED_ERROR: 'Total of all weightings must sum to 100%.',
    INVALID_NODE_COUNT_ERROR: 'Number of rows should be equals to number of nodes available under the selected Level.',
    NO_NODE_SELECTED_ERROR: 'Please select a sub node.',
    DUPLICATE_NODE_ERROR: 'Duplicate nodes are not allowed.'
  },
  DEFAULT_LEVEL: 1
};

export const LIQUIDATE_CONSTANTS = {
  TOOL_NAME: 'Liquidate',
  DISTRIBUTION_METHOD: {
    DISTRIBUTION_SLEEVE: 'DistributionSleeve',
    INDIVIDUAL_SLEEVES: 'IndividualSleeves'
  },
  VIEW: {
    TRADE_OPTION_TAB: 'TradeOptionsTab',
    SELECTION_TAB: 'SelectionTab'
  },
  TRADE_FILTER_METHOD: {
    PORTFOLIO: 'portfolio',
    ACCOUNT: 'account',
    EXCEL_IMPORT: 'excelImport',
    TRADE_GROUPS_FOR_ACCOUNT: 'tradeGroupsForAccount',
    TRADE_GROUPS_FOR_PORTFOLIO: 'tradeGroupsForPortfolio'
  },
  RECORD_TYPES: {
    PORTFOLIO: 'portfolio',
    ACCOUNT: 'account'
  },
  REDIRECT_ROUTE: '/eclipse/tradeorder/list',
  TOOL_ROUTE: '/eclipse/tradetool/liquidate',
  SESSION_SELECTED_IDS_KEY: 'selectedIds',
  SESSION_INSTANCE_ID_KEY: 'instanceId'
};

export const TABS_MODEL = {
  PORTFOLIO: 'P',
  ACCOUNT: 'A'
};

export const NUMBER_FORMAT_STYLES = {
  PERCENT: 'percent'
};

export const LOCALS = {
  EN_US: 'en-US'
};

export const RESET_EXCLUDED_CASH_VALUES = {
  VALUE: '0.00'
};

export const ExcludedSecurity = 'Excluded security';

export const TAB_VIEW_TYPE = {
  PORTFOLIO: 'portfolio',
  ACCOUNT: 'account'
};

export const TRADE_AMOUNT_TYPE = {
  DOLLAR: '$',
  PERCENTAGE: '%'
};

export const ACCOUNT_TAB_NAV_ACTION = {
  SLEEVE_TRANSFER: 'SLEEVE_TRANSFER'
};

export const ACCOUNT_AUTO_REBALANCE_CONSTANTS = {
  DAYS: {
    MINIMUM: 1,
    MAXIMUM: 31
  },
  DEFAULT_TYPE_ID: 1
};

export const AUTO_REBALANCE_TYPE = {
  None: 1,
  Monthly: 2,
  Quarterly: 3,
  SemiAnnual: 4,
  Annual: 5
};
